<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-xl font-extrabold text-gray-900">Réinitialisation de mot de passe</h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6">
          <div>
            <label for="password" class="block text-sm font-medium text-gray-700"> Mot de passe </label>
            <div class="mt-1">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                autocomplete="password"
                required=""
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label for="passwordConfirm" class="block text-sm font-medium text-gray-700">
              Confirmation du mot de passe
            </label>
            <div class="mt-1">
              <input
                id="passwordConfirm"
                v-model="passwordConfirm"
                name="passwordConfirm"
                type="password"
                autocomplete="password"
                required=""
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>

          <div>
            <Button @clicked="resetPassword" :loading="loading"> Réinitialiser </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import authenticationApi from '@/api/authentication'
import { mapActions } from 'pinia'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'

export default {
  components: {
    Button
  },
  data() {
    return {
      loading: false,
      password: undefined,
      passwordConfirm: undefined
    }
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useAppStateStore, ['updateUser']),
    async resetPassword() {
      if (this.loading) return
      this.loading = true
      try {
        const response = await authenticationApi.resetPassword(
          this.password,
          this.passwordConfirm,
          this.$route.query.authorization
        )

        localStorage.setItem('Authorization', response.data.token)
        delete response.data.token

        this.updateUser(response.data)
        this.showToast({
          success: true,
          title: 'Félicitations',
          message: 'Votre mot de passe a bien été mis à jour'
        })
        this.password = undefined
        this.passwordConfirm = undefined

        this.$router.push(this.$route.query.redirect || '/shop')
      } catch (err) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: err.response.data.message
        })
        console.error(err)
      }
      this.loading = false
    }
  }
}
</script>
