import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Team from '@/views/Team.vue'
import MyAccount from '@/views/MyAccount.vue'
import MySubscriptions from '@/views/MySubscriptions.vue'
import MyInvoices from '@/views/MyInvoices.vue'
import Contact from '@/views/Contact.vue'
import PasswordForgotten from '@/views/PasswordForgotten.vue'
import ResetPassword from '@/views/ResetPassword'
import PaymentJourney from '@/views/PaymentJourney.vue'
import ActivateGift from '@/views/ActivateGift.vue'
import Shop from '@/views/Shop.vue'
import CartJourney from '@/views/CartJourney.vue'
import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'
import WineDetail from '@/views/WineDetail.vue'
import { useAppStateStore } from '@/stores/appState'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      publicRoute: true,
      title: "La Chaussette | Vins & Dégustations à l'aveugle",
      metaTags: [
        {
          name: 'description',
          content:
            "Goûtez au plaisir de la dégustation à l'aveugle avec les coffrets La Chaussette. Chaque mois, deux bouteilles de vins sélectionnés avec soin par nos compères."
        },
        {
          property: 'og:description',
          content:
            "Goûtez au plaisir de la dégustation à l'aveugle avec les coffrets La Chaussette. Chaque mois, deux bouteilles de vins sélectionnés avec soin par nos compères."
        }
      ]
    }
  },
  {
    path: '/team',
    name: 'Les Compères',
    component: Team,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Les Compères',
      metaTags: []
    }
  },
  {
    path: '/my-account',
    name: 'Mon Compte',
    component: MyAccount,
    meta: { requiresAuth: true, publicRoute: true, title: 'La Chaussette | Mon Compte', metaTags: [] }
  },
  {
    path: '/my-subscriptions',
    name: 'Mes Abonnements',
    component: MySubscriptions,
    meta: { requiresAuth: true, publicRoute: true, title: 'La Chaussette | Mes Abonnements', metaTags: [] }
  },
  {
    path: '/my-invoices',
    name: 'Mes Factures',
    component: MyInvoices,
    meta: { requiresAuth: true, publicRoute: true, title: 'La Chaussette | Mes Factures', metaTags: [] }
  },
  {
    path: '/contact',
    name: 'Nous Contacter',
    component: Contact,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Nous Contacter',
      metaTags: []
    }
  },
  {
    path: '/password-forgotten',
    name: 'Mot de passe oublié',
    component: PasswordForgotten,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Mot de passe oublié',
      metaTags: []
    }
  },
  {
    path: '/reset-password',
    name: 'Réinitialisation du mot de passe',
    component: ResetPassword,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Réinitialisation du mot de passe',
      metaTags: []
    }
  },
  {
    path: '/activate-gift',
    name: 'Activez votre cadeau',
    component: ActivateGift,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Activez votre cadeau',
      metaTags: []
    }
  },
  {
    path: '/payment-journey',
    name: 'Commande',
    component: PaymentJourney,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Commande',
      metaTags: []
    }
  },
  {
    path: '/shop',
    name: 'La Boutique',
    component: Shop,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | La Boutique',
      metaTags: []
    }
  },
  {
    path: '/cart',
    name: 'Mon Panier',
    component: CartJourney,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Mon Panier',
      metaTags: []
    }
  },
  {
    path: '/login',
    name: 'Connexion',
    component: Login,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Connexion',
      metaTags: []
    }
  },
  {
    path: '/signup',
    name: 'Inscription',
    component: Signup,
    meta: {
      publicRoute: true,
      title: 'La Chaussette | Inscription',
      metaTags: []
    }
  },
  {
    path: '/wines/:id',
    name: "Détail d'un vin",
    component: WineDetail,
    meta: { requiresAuth: true, publicRoute: true, title: "La Chaussette | Détail d'un vin", metaTags: [] }
  },
  {
    path: '/cart',
    name: "Parcours d'achat de la boutique",
    component: CartJourney,
    meta: {
      requiresAuth: true,
      publicRoute: true,
      title: "La Chaussette | Parcours d'achat de la boutique",
      metaTags: []
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

const paramsToPreserve = [
  'page',
  'priceRangesSelected',
  'categoriesSelected',
  'search',
  'countriesSelected',
  'selectionsSelected'
]

const getRouteWithParamsPreserved = (to, from) => {
  let atLeastOneParamToPreserve = false

  for (const paramToPreserve of paramsToPreserve) {
    if (from?.query?.[paramToPreserve] && !to?.query?.[paramToPreserve]) {
      to.query[paramToPreserve] = from.query[paramToPreserve]
      atLeastOneParamToPreserve = true
    }
  }
  return atLeastOneParamToPreserve ? to : null
}

router.beforeEach((to, from) => {
  // Set metadata
  document.title = to.meta.title || "La Chaussette | Vins & Dégustations à l'aveugle"

  let descriptionTag = document.querySelector('meta[name="description"]')
  if (!descriptionTag) {
    descriptionTag = document.createElement('meta')
    descriptionTag.setAttribute('name', 'description')
    document.head.appendChild(descriptionTag)
  }

  let ogDescriptionTag = document.querySelector('meta[property="og:description"]')
  if (!ogDescriptionTag) {
    ogDescriptionTag = document.createElement('meta')
    ogDescriptionTag.setAttribute('property', 'og:description')
    document.head.appendChild(ogDescriptionTag)
  }

  const description = to.meta.metaTags?.find(tag => tag.name === 'description')?.content
  const ogDescription = to.meta.metaTags?.find(tag => tag.property === 'og:description')?.content

  if (description) descriptionTag.setAttribute('content', description)
  if (ogDescription) ogDescriptionTag.setAttribute('content', ogDescription)

  // Redirect logic
  const appStateStore = useAppStateStore()
  if (to.meta.requiresAuth && !appStateStore.user) {
    return {
      path: '/login',
      query: { redirect: to.fullPath }
    }
  }

  if (to.path !== '/shop' || from.path !== '/shop') {
    return getRouteWithParamsPreserved(to, from)
  }
})

export default router
